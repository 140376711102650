<template>
  <div class="pb-5">
    <v-expansion-panels
      v-for="(collection, index) in collections"
      :id="'collection-' + collection.id"
      :key="collection.name + index"
      :value="
        (collection.name == 'My Pinned Reports' &&
          collection.reports.length !== 0) ||
        collectionScrollId == collection.id ||
        checkedCollectionsName.includes(collection.name) ||
        openSharingCollection.includes(collection.id)
          ? 0
          : 1
      "
      class="box-shadow collection-accord-container"
    >
      <hb-expansion-panel
        v-if="
          !(
            collection.name == 'Custom Reports' &&
            collection.reports.length == 0
          )
        "
      >
        <template v-slot:title>
          <v-row class="ma-0 pa-0">
            <hb-checkbox
              v-if="share && (addReportCollection !== collection.name) && collection.reports.length"
              :value="indeterminateCheckbox[index]"
              :id="'check' + index"
              v-model="indeterminateCheckbox[index]"
              :indeterminate="
                indeterminateCheckbox[index]
                  ? false
                  : collection.reports.some(report => checkedCollections.includes(report.id))
              "
              :ref="'check' + index"
              @click.native.stop="checkAll(collection.id, index)"
              :disabled="disableCollCheckbox(index)"
            />
            <HbIcon :mdi-code="getAccordionIcon(collection.name)" class="pr-1" />
            <span class="ml-1 collection-name">
              {{ collection.name }}
              <span class="ml-1">({{ collection.reports.length }})</span>
            </span>
          </v-row>
        </template>
        <template v-slot:actions v-if="!share && (collection.name=='Bankers Box' || collection.name=='Investor Reports')">
          <hb-btn
            icon
            tooltip="Share"
            class="pr-2"
            style=" cursor: pointer;"
            :disabled="collection.reports.length == 0"
            active-state-off
            @click.native.stop="
              openShareScheduleModel(collection.id, false, collection.name, collection.reports.length == 0)
            "
            >mdi-user-actions-custom-1
          </hb-btn>
          <hb-menu options>
            <v-list style=" cursor: pointer;">
              <v-list-item :disabled="collection.reports.length == 0">
                <v-list-item-title
                  @click="openShareScheduleModel(collection.id, false, collection.name, collection.reports.length == 0)"
                  >Share Collection</v-list-item-title
                >
              </v-list-item>
              <v-list-item :disabled="collection.reports.length == 0">
                <v-list-item-title
                  @click="openShareScheduleModel(collection.id, true, collection.name, collection.reports.length == 0)"
                  >Set up repeat Delivery</v-list-item-title
                >
              </v-list-item>
              <v-list-item>
                <v-list-item-title @click="addReport(collection.id, collection.name)">Add Reports</v-list-item-title>
              </v-list-item>
            </v-list>
          </hb-menu>
        </template>
        <template v-slot:content>
          <v-hover v-slot:default="{ hover }">
            <div
              v-if="collection.reports.length !== 0"
              :id="'scrollList' + index"
              class="py-2 px-2 collection-container"
              :class="{
                'report-container': collection.name === 'My Pinned Reports'
              }"
            >
            <v-row class="px-3">
              <v-col class="pt-0 pb-0" v-for="(reports, columnIndex) in splitReportsToColumns(collection.reports)" :key="generateUniqueId(reports)">
                <div class="mt-3 mb-3" v-for="(report, index2) in reports" :key="index2">
                  <hb-link
                    :href="'#searchResult' + report.id + collectionScrollId"
                    v-if="collection.name !== 'My Pinned Reports'"
                    style="display:none;"
                    :id="'href2' + report.id"
                    >Link</hb-link
                  >

                  <report-accordian
                    :id="
                      collection.name !== 'My Pinned Reports'
                        ? 'href' + report.id + collectionScrollId
                        : ''
                    "
                    :report="report"
                    :collectionIndex="index"
                    :reportScrollId="reportScrollId"
                    :index="index2"
                    :collectionName="collection.name"
                    :collectionScrollId="collectionScrollId"
                    :isShare="share"
                    :isMultiple="share"
                    :indeterminateCheckbox="indeterminateCheckbox"
                    :isFieldError="isFieldError"
                    :shareCollectionFlag="shareCollectionFlag"
                    :openSharingReports="openSharingReports"
                    :collectionId="collection.id"
                    @getGroupingProfiles="getGroupingProfiles"
                    @handleSelectFocusrofiles="getGroupingProfiles"
                    @setUpdatedCollection="setUpdatedCollection"
                    @unCheck="unCheck"
                    @handleSelectFocus="handleSelectFocus"
                    @resetShareCollectionFlag="resetShareCollectionFlag"
                    :checkedCollections="checkedCollections"
                  ></report-accordian>
                </div>
              </v-col>
            </v-row>
            </div>
            <div
              v-else
              class="py-4 px-4 collection-container"
              :class="{  'report-container': collection.name === 'My Pinned Reports' }"
            >
              <hb-empty-state
                v-if="collection.name == 'My Pinned Reports'"
                backgroundColor="#F9FAFB"
                header="There are 0 Reports in this Collection."
                message="You can pin reports to this collection by clicking on the Pin Icon next to any report."
                :showBtn="false"
              ></hb-empty-state>
              <hb-empty-state
                v-else-if="['Bankers Box', 'Investor Reports'].includes(collection.name)"
                backgroundColor="#F9FAFB"
                header="There are 0 Reports in this Collection."
                message="You can add reports here by clicking on the ellipsis at the right corner of this collection."
                :showBtn="false"
              ></hb-empty-state>
              <hb-empty-state
                v-else
                backgroundColor="#F9FAFB"
                header="There are 0 Reports in this Collection."
                message="You can create custom dyanmic reports and save them to this collection."
                btn-txt="Learn how to Create Custom Reports"
                btnColor="secondary"
              ></hb-empty-state>
            </div>
          </v-hover>
        </template>
      </hb-expansion-panel>
    </v-expansion-panels>
    <report-share-modal
      v-if="openShareModal"
      v-model="openShareModal"
      @close="openShareModal = false"
      :checkedReports="checkedReports"
      :openForSchedule="openForSchedule"
      :reportInModal="shareCollectionFlag"
      @closeShareSchedule="openShareModal = false"
    >
    </report-share-modal>
  </div>
</template>
<script>
import ReportAccordian from "./ReportAccordian.vue";
import ReportCard from "./Common/ReportCard.vue";
import ReportShareModal from "./Common/ReportShareModal.vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { EventBus } from "../../EventBus";
import { notificationMixin } from "@/mixins/notificationMixin.js";

export default {
  name: "CollectionAccordion",
  components: {
    ReportAccordian,
    ReportCard,
    ReportShareModal
  },
  mixins: [notificationMixin],

  data() {
    return {
      panel: [],
      open: [],
      close: [],
      openCollection: [],
      indeterminateCheckbox: [],
      indeterminateCheckboxCheckAll: [],
      isBgColor: false,
      openShareModal: false,
      shareCollectionFlag: false,
      checkedReports: [],
      openSharingCollection: [],
      openForSchedule: false,
      fileArr: [],
      openSharingReports: '',
      columns: 3, //default column number for reports should be 3
      screenWidth: 0,
      screenSizeConfig: [ //The screen size configurations should be in ascending order
        {
          maxWidth: 600,
          maxColumns: 1
        },
        {
          maxWidth: 1264,
          maxColumns: 2
        },
        {
          maxWidth: 1904,
          maxColumns: 3
        },
        {
          maxWidth: 4000,
          maxColumns: 4
        }
      ]
    };
  },
  props: [
    "collectionScrollId",
    "reportScrollId",
    "closeAllCollections",
    "share",
    "checkedCollections",
    "errorName",
    "checkedCollectionsName"
  ],
  created() {
    this.fetchCollections();
    EventBus.$on(`cancelMultipleCheckbox`, this.cancelCheckBox);
  },
  mounted() {
    this.updateScreenWidth();
    this.addResizeEventListener();
  },
  destroyed() {
    window.removeEventListener("resize", this.updateScreenWidth);
    EventBus.$off(`cancelMultipleCheckbox`, this.cancelCheckBox);
  },
  computed: {
    collections() {
      let collection = JSON.parse(JSON.stringify(this.$store.getters["reportLibraryStore/collectionStore"]));
    if(!this.hasPermission('mini_charm')){
          collection = collection.filter(item => item.name !== "Charm Reports")
    }    
    // If nationalAccountFeature is '1', remove specific reports from the 'reports' array
    if (this.getSettings.nationalAccountFeature === '0') {
      collection.forEach(item => {
        if (item.reports && Array.isArray(item.reports)) {
          // Filter out the report with template 'national_account_unit_list'
          item.reports = item.reports.filter(report => report.template !== 'national_account_unit_list' && report.template !== 'active_national_accounts');
        }
      });
    }
      return collection
    },
    isFieldError() {
      if (this.share) {
        this.fileArr = this.errorName;
      }
      return this.fileArr;
    },
    ...mapGetters({
      hasPermission: "authenticationStore/rolePermission",
      addReportCollection: 'reportLibraryStore/getAddReportCollection',
      customCollReports: 'reportLibraryStore/getCustomCollReports',
      getSettings: 'authenticationStore/getSettings',
    }),

    disableCollCheckbox() {
      return (index) => {
       return ( 
        this.addReportCollection &&
        this.customCollReports[this.addReportCollection] &&
        this.collections[index].reports.every((report) =>
          this.customCollReports[this.addReportCollection].includes(report.id)
        ));
      }
    },
    updatedColumns(){
      let screenConfig = this.screenSizeConfig.find(obj => obj.maxWidth >= this.screenWidth);
      return screenConfig ? screenConfig.maxColumns : this.columns;
    },
  },
  methods: {
    ...mapActions({
      fetchCollections: "reportLibraryStore/fetchCollections",
      getSpaceGroup: "reportLibraryStore/getSpaceGroup",
      getLoadedCollections: "reportLibraryStore/getLoadedCollections",
      deleteCollections: "reportLibraryStore/deleteCollections",
      setScheduleType: "reportLibraryStore/setScheduleType"
    }),
    ...mapMutations({
      setAddReportCollection: 'reportLibraryStore/setAddReportCollection'
    }),
    resetShareCollectionFlag() {
      this.shareCollectionFlag = false;
    },
    addResizeEventListener() {
      window.addEventListener("resize", this.updateScreenWidth);
    },
    updateScreenWidth() {
      this.screenWidth = window.innerWidth;
    },
    splitReportsToColumns(collection) {
      let arrays = Array.from({ length: this.updatedColumns }, () => []) //Create n number of empty arrays based on columns
      collection?.forEach((report, index) => {
        //push reports into each columns
        let arrayIndex = index % this.updatedColumns;
        arrays[arrayIndex].push(report);
      })
      return arrays;
    },
    generateUniqueId(reports) {
      return reports?.map(report => report.id).join("-");
    },
    handleSelectFocus(data) {
      // this.$nextTick(() => {
      //     this.fileArr = this.fileArr.filter(item => item !== data);
      //   });
      if (this.share) {
        this.$emit("handleDropdownFocus", data);
      } else if (this.shareCollectionFlag) {
        this.$nextTick(() => {
          this.fileArr = this.fileArr.filter(item => item !== data);
        });
      }
    },
    checkFileFieldAndOpen(colId, shareScheduleFlag, collectioName, disabled) {
      if (this.fileArr.length) {
        this.showMessageNotification({
            description: "Please enter all the fields for the below reports",
            list: this.fileArr,
        })
      } else {
        this.openForSchedule = shareScheduleFlag ? true : false;
        if(this.openForSchedule) {
          const scheduleType = collectioName == "Bankers Box" ? "banker_box" : "investor_reports";
          this.setScheduleType(scheduleType);
        }
        this.openShareModal = true;
      }
    },
    openShareScheduleModel(colId, shareScheduleFlag, collectioName, disabled) {
      if(!disabled) {
      EventBus.$emit("startShareCollectionFlag")
      this.fileArr = [];
      this.shareCollectionFlag = true;
      this.openSharingCollection.push(colId);
      this.checkedReports = [];
      const reports = this.collections.find(el => el.id == colId).reports;
      this.openSharingReports = colId;
      this.checkedReports = reports.map(element => {
        const obj = {
          reportDetails: element.reportDetails,
          report: element
        };
        return obj;
      });
      this.checkedReports.map(report => {
        if (!report.reportDetails || !report.reportDetails.file_type) {
          this.fileArr.push(report.report.name);
        }
      });
      this.checkFileFieldAndOpen(colId, shareScheduleFlag, collectioName, disabled);
      }
    },
    setUpdatedCollection(report, index, collectionIndex) {
      let payload = {
        report: report,
        index: index,
        collectionIndex: collectionIndex
      };
      this.deleteCollections(payload);
    },
    async getGroupingProfiles(report, property_id, i, collectionIndex) {
      if (!this.hasGroupingProfile(report)) return;
      let payload = {
        property_id: property_id,
        collectionIndex: collectionIndex,
        index: i
      };
      this.getSpaceGroup(payload);
    },
    hasGroupingProfile(report) {
      return typeof report.unit_group_profile_id !== "undefined";
    },
    checkAll(colId, index) {
      this.$emit("checkAll", colId, this.indeterminateCheckbox[index]);
      EventBus.$emit(`check-${index}`);
    },
    unCheck(index) {
      this.$set(this.indeterminateCheckbox, index, null);
    },
    cancelCheckBox() {
      this.indeterminateCheckbox.splice(0);
    },
    addReport(collection_id, collection_name) {
      this.shareCollectionFlag = false;
      this.setAddReportCollection(collection_name);
      this.$emit('setAddReport', collection_id, collection_name);
    },
    getAccordionIcon(name){
      if(name){
        if(name == 'My Pinned Reports'){
          return 'mdi-custom-pin';
        } else if(name == 'Custom Reports'){
          return 'mdi-account';
        } else if(name == 'Management Reports'){
          return 'mdi-account-tie';
        } else if(name == 'Financial Reports'){
          return 'mdi-finance';
        } else if(name == 'Occupancy Reports'){
          return 'mdi-facility-custom-3'
        } else if(name == 'Operations Reports'){
          return 'mdi-settings'
        } else if(name == 'Coverage Reports'){
          return 'mdi-umbrella'
        } else if(name == 'Delinquency Reports'){
          return 'mdi-gavel'
        } else if(name == 'Merchandise Reports'){
          return 'mdi-cart-outline'
        } else if(name == 'Application Reports'){
          return 'mdi-custom-reports-application'
        } else if(name == 'Bankers Box'){
          return 'mdi-bank'
        } else if(name == 'Investor Reports'){
          return 'mdi-account-group'
        } else if(name == 'Payment Processing Reports'){
          return 'mdi-custom-financial-cash-sync'
        } else {
          return 'mdi-folder-outline';
        }
      } else {
        return 'mdi-folder-outline';
      }
    }
  }
};
</script>

<style scoped>
.collection-name {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}
.report-container {
  background: #e0f5f5 !important;
}
.collection-container {
  background: #f9fafb;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  padding-left: 13px !important;
  padding-right: 13px !important;
}

.collection-container .row > div:not(:first-child),
.collection-container .row > div:not(:last-child) {
  padding-left: 5px;
  padding-right: 5px !important;
}

.box-shadow {
  box-shadow: inset 0px -1px 0px rgba(142, 142, 143, 0.15) !important;
}
</style>
<style>
.collection-accord-container > .v-expansion-panel > .theme--light.v-divider {
  border-color: transparent;
}

.collection-accord-container
  > .v-expansion-panel.v-expansion-panel--active
  > .theme--light.v-divider {
  border-color: rgba(0, 0, 0, 0.12);
}
</style>
