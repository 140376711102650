<template>
  <v-card class="mt-4 mb-2 pa-4 hb-elevation-medium">
    <div v-if="isAddReport">
      <v-row class="ma-0">
        <v-col :cols="9" class="pa-0">
          <span class="font-weight-medium hb-font-body">
            Adding <span class="report-label"> {{selectedCount}} {{selectedCount === 1 ? 'Report' : 'Reports'}} </span> to the {{collectionType}}
          </span>
        </v-col>
        <v-col :cols="3" class="pa-0 d-flex align-end justify-end">
          <div class="d-flex align-center">
            <hb-link class="mr-3" @click="shareReport(null)">Cancel</hb-link>
            <hb-btn color="primary" :disabled="isLoading($options.name) || !selectedCount" @click="$emit('addReportApiCall')">Add Reports</hb-btn>
          </div>
        </v-col>
      </v-row>
    </div>

    <div v-else>
      <span class="font-weight-regular mb-3">Choose how you would like to share these reports.</span>
      <span class="font-weight-medium" v-if="selectedOption">
        <span class="hb-link hb-font-caption font-weight-regular float-right">
          {{selectedCount}} {{selectedCount === 1 ? 'Report' : 'Reports'}} Selected
      </span>
      </span>
      <v-row class="ma-0 pt-3">
        <v-col :cols="9" class="pa-0">
          <hb-radio-group hide-details v-model="selectedOption" @change="shareReport(selectedOption)">
            <hb-radio value="oneTime">
              <template v-slot:label>
                <span class="v-label font-weight-medium">{{reportShare.oneTime.header}}</span>
              </template>
            </hb-radio>
            <span class="pl-8 font-weight-regular hb-text-light hb-font-body">
              {{reportShare.oneTime.description}}
            </span>
            <hb-radio value="repeated">
              <template v-slot:label>
                <span class="v-label font-weight-medium">{{reportShare.repeated.header}}</span>
              </template>
            </hb-radio>
            <span class="pl-8 font-weight-regular hb-text-light hb-font-body">
              {{reportShare.repeated.description}}
            </span>
          </hb-radio-group>
        </v-col>
        <v-col :cols="3" class="pa-0 d-flex align-end justify-end">
          <div class="d-flex align-center">
            <hb-link class="mr-3" @click="cancelSelection()">Cancel</hb-link>
            <hb-btn color="primary" :disabled="isLoading($options.name) || !selectedCount" @click="$emit('openMultipleShareModal')">Add Recipients</hb-btn>
          </div>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "CollectionAccordion",
  data() {
    return {
      selectedOption: null,
      reportShare: {
        oneTime: {
          header: "One Time Delivery",
          description: "Select reports and share them with your co-workers and/or people outside your company."
        },
        repeated: {
          header: "Setup Repeat Delivery of Reports",
          description: "Select reports and create a repeat delivery schedule to share reports with your co-workers and/or people outside your company. You can choose to share the selected reports at different frequencies, such as “End of Day” for daily reports, “End of Week” for weekly reports, etc."
        }
      }
    };
  },
  props: ['shareReport', 'type', 'selectedCount', 'isAddReport', 'collectionType'],
  methods: {
    cancelSelection() {
      this.selectedOption = null;
      this.shareReport(null);
      this.$emit('close');
    }
  }
};
</script>
<style scoped>
.report-label {
  border-bottom: 1px dashed #00848E;
  color: #00848E;
}
</style>