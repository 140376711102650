<template>
  <v-expansion-panel @click="$emit('fetchStructure')">
    <v-expansion-panel-header
      :expand-icon="''"
      class="pl-3 pt-0 pb-0 pr-3"
      style="min-height: 48px !important"
    >
      <v-row>
        <v-col class="d-flex align-center " md="9">
          <span>
            <slot name="title"></slot>
          </span>
        </v-col>
        <v-col class="pa-0 ma-0 d-flex justify-end actions-slot" md="3">
          <slot name="actions"> </slot>
        </v-col>
      </v-row>
    </v-expansion-panel-header>
    <v-divider></v-divider>
    <v-expansion-panel-content>
      <v-container class="mb-0 pb-0 pt-0">
        <slot name="content"> </slot>
      </v-container>
      <v-divider v-if="!isShare"></v-divider>
      <v-container class="mt-3 pt-0" v-if="!isShare">
        <v-row class="ma-0 pa-0">
          <v-col class="pa-0 ma-0 d-flex align-center justify-start">
            <slot name="share-footer"></slot>
          </v-col>
          <v-col class="pa-0 ma-0 d-flex align-center justify-end">
            <span class="mr-3">
              <slot name="cancel-footer"></slot>
            </span>
            <span>
              <slot name="action-footer"></slot>
            </span>
          </v-col>
        </v-row>
      </v-container>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script type="text/babel">
export default {
  name: "ReportCard",
  data() {
    return {
      panel: []
    };
  },
  props: ["report", "isShare"],
  methods: {}
};
</script>

<style scoped>
.v-expansion-panel::before {
  box-shadow: none;
}
.v-expansion-panel-header:not(.v-expansion-panel-header--mousedown):focus::before 
{
  opacity: 0 !important;
}
.actions-slot {
 padding-top: 3px !important;
}
</style>
